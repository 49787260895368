import React, { Fragment, useMemo } from 'react';

import { emptySelectValue, FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup from 'components/shared/form/InputGroup';
import ToggleGroup, { ToggleOption } from 'components/shared/form/ToggleGroup';
import { useForm } from 'context/form';
import { useAddressOptions } from 'hooks/useAddressOptions';
import Group from 'models/Group';
import Profile from 'models/Profile';
import { useInfiniteGroups } from 'services/api/group';

export type RehabFacilityInputGroupProps = {
  profile: Profile;
};

function RehabFacilityInputGroup({ profile }: RehabFacilityInputGroupProps) {
  const { values, meta, errors, setValue } = useForm<FormValues, FormMeta>();
  const owner = values.owner;

  const asyncGroupOptions = useInfiniteGroups({
    clientIdOrNetworkClientId: owner?.clientId,
    type: values.locationType?.value,
    include: 'group_type',
    sortBy: 'name asc',
  });

  const addressProps = useAddressOptions();

  const patientInQueue = !meta.currentRehabState || meta.currentRehabState.queue;
  const editingNonQueuePatient = meta.mode == PatientFormMode.Edit && !patientInQueue;
  const locationTypeIsDisabled = !owner?.id || editingNonQueuePatient;

  const locationTypeOptions = useMemo(() => {
    let enabledProviderTypes = profile.enabledProviderTypes;
    if (!meta.isManagerUser) {
      const ownerClientEnabledProviderTypes = owner?.client?.enabledProviderTypes.map(
        (enabledProviderType) => enabledProviderType.apiName
      );

      enabledProviderTypes = profile.enabledProviderTypes.filter((userProviderType) =>
        ownerClientEnabledProviderTypes?.includes(userProviderType.apiName)
      );
    }

    return enabledProviderTypes.map((groupType) => ({ label: groupType.displayName, value: groupType.apiName }));
  }, [meta.isManagerUser, owner?.client?.enabledProviderTypes, profile.enabledProviderTypes]);

  const hasSingleProviderType = locationTypeOptions.length === 1;

  const isProviderTypeVisible = patientInQueue && locationTypeOptions.length > 1;
  const isProviderTypeDisabled =
    locationTypeIsDisabled || !!meta.patientHasActiveServiceRefusals || !!meta.latestLocationEpisodeIsArchived;

  const isRehabFacilityVisible = !!values.locationType || hasSingleProviderType;

  const groupLabel = values.locationType?.label ? values.locationType.label : locationTypeOptions[0]?.label;

  const handleLocationTypeChange = (change: ToggleOption) => {
    const previousLocationType = values.locationType?.value;

    setValue('locationType', change);

    if (change.value != previousLocationType) {
      setValue('rehabFacility', null, { validate: false });
      setValue('caseManager', null, { validate: false });
      setValue('utilizationManager', null, { validate: false });
    }
  };

  return (
    <Fragment>
      <InputGroup data-cy='providerType' title='What care is the patient receiving?' visible={isProviderTypeVisible}>
        <ToggleGroup
          selectedOption={values.locationType ?? emptySelectValue}
          disabled={isProviderTypeDisabled}
          options={locationTypeOptions}
          getOptionValue={(prop) => prop.value}
          getOptionLabel={(prop) => prop.label}
          onChange={handleLocationTypeChange}
          autoSelectFirstOption={hasSingleProviderType}
        />
      </InputGroup>
      <InputGroup
        title={groupLabel}
        error={errors.rehabFacility}
        data-cy='rehabFacility'
        visible={isRehabFacilityVisible}>
        <Select<Group>
          {...asyncGroupOptions}
          {...addressProps}
          name={groupLabel}
          placeholder={groupLabel}
          getOptionValue={(prop) => prop.id}
          getOptionLabel={(prop) => prop.name}
          onChange={(change) => setValue('rehabFacility', change)}
          disabled={isProviderTypeDisabled}
          value={values.rehabFacility}
          hasError={!!errors.rehabFacility}
        />
      </InputGroup>
    </Fragment>
  );
}

export default RehabFacilityInputGroup;
